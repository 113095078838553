/* Service info card */

import {
  Divider,
  Flex,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { FaCheckCircle } from "react-icons/fa"

const ServiceDetailCard = ({ title, description, svgPath, items }) => {
  return (
    <Flex
      direction="column"
      maxW="400px"
      w="100%"
      p={4}
      borderRadius="8px"
      boxShadow="rgba(50, 50, 93, 0.1) 0px 10px 24px -5px, rgba(0, 0, 0, 0.3) 0px 5px 13px -5px"
    >
      <Flex justify="space-between" align="center">
        <Icon viewBox="0 0 24 24" w={12} h={12} color="yellow.500">
          <path fill="currentColor" d={svgPath} />
        </Icon>
        <Text
          fontWeight="semibold"
          fontSize={["2xl", "2xl", "3xl"]}
          color="teal.700"
        >
          {title}
        </Text>
      </Flex>
      <Divider my={2} borderColor="gray.400" />
      <Text minH="100px">{description}</Text>
      <Divider my={2} borderColor="gray.400" />
      <List spacing={2}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListIcon as={FaCheckCircle} color="teal.400" /> {item}
          </ListItem>
        ))}
      </List>
    </Flex>
  )
}

export default ServiceDetailCard
