/* Services page component */

import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import React from "react"
import { FaCamera, FaFileInvoiceDollar } from "react-icons/fa"
import Seo from "../seo"
import ServiceDetailCard from "./service-card"

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "services" } } }
        sort: { fields: frontmatter___title }
      ) {
        nodes {
          frontmatter {
            title
            icon
            description
            items
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo title="Services" />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" mb={2} textAlign="center">
          Services
        </Heading>
        <Text color="gray.50" textAlign="center">
          What we offer
        </Text>
      </Flex>
      <Center>
        <Flex
          direction="column"
          justify="space-evenly"
          align="center"
          minH="50vh"
          py={[4, 8]}
        >
          <Stack
            justify="center"
            direction={["column", "row"]}
            spacing={[4, 8]}
            my={[4, 8]}
          >
            <Button
              as={GatsbyLink}
              to="/projects"
              colorScheme="yellow"
              color="teal.800"
              leftIcon={<FaCamera />}
              minW="200px"
            >
              View Projects
            </Button>
            <Button
              as={GatsbyLink}
              to="/contact"
              colorScheme="teal"
              bg="teal.600"
              _hover={{ bg: "teal.800" }}
              leftIcon={<FaFileInvoiceDollar />}
              minW="200px"
            >
              Get Free Estimate
            </Button>
          </Stack>
          <Container maxW="container.xl" my={[4, 8]}>
            <Center>
              <SimpleGrid columns={[1, 1, 2]} spacing={[4, 8]}>
                {services.map((service, index) => (
                  <ServiceDetailCard
                    key={index}
                    title={service.frontmatter.title}
                    svgPath={service.frontmatter.icon}
                    description={service.frontmatter.description}
                    items={service.frontmatter.items}
                  />
                ))}
              </SimpleGrid>
            </Center>
          </Container>
        </Flex>
      </Center>
    </>
  )
}

export default ServicesPage
